<template>
  <div class="container">
    <div class="clearfix">
      <div class="test-results__title">
        {{ $t('test-results.title-second-step') }}
      </div>
      <div class="test-results__tape">
        <div class="container h-100">
          <div class="test-results__tape-text">
            {{ $t('test-results.title-2') }}
          </div>
        </div>
      </div>
      <div class="test-results__date-block">
        <div class="test-results__date">
          <img src="/images/test-date.svg" class="mr-3" width="24" height="24">
          {{ $t('test-results.test-date') }}
          {{ dataEnd.substr(0, 10).split('-').reverse().join('.') }}
        </div>
      </div>
      <div style="margin-top: 40px" class="test-results__sub-title">{{ $t('test-results.results') }}</div>
      <section class="test-results__section" v-for="(chart, index) in charts" :key="index" :id="'chart-' + index">
        <div class="test-results__sub-title">{{ chart.subject.name }}</div>
        <div v-for="(topic, key) in chart.topics" class="test-results__section-row mb-5">
          <div class="test-results__img">
            <img height="400px" :src="topic.topic.image">
          </div>
          <div class="test-results__card">
            <div class="test-results__card-title--recomend">
              {{topic.topic.name}}
            </div>
            <div class="recomendation-wh">
              <div class="test-results__card-title--recomend-min">
                {{ topic.level.name }}
              </div>

              <div>{{ topic.recommendation }}</div>
            </div>

            <div v-if="topic.result_recommendation"  class='recomendation-bg'>
              <div class="test-results__card-title--recomend-min">{{ $t('profile.children.recommendation') }}</div>
              {{ topic.result_recommendation }}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="recommendation-title">{{ $t('profile.children.recommendation') }}</div>
        <div class="recomendation-wrapper">
          <div>
              {{second_step}}
          </div>
        </div>
      </section>
      <vue-html2pdf
          v-show="false"
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :filename="$t('test-results.download-file-name') + ' ' + fio"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"

          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
      >
        <div slot="pdf-content" class="container">
          <div class="clearfix">
            <div class="test-results__title">
              {{ $t('test-results.title-second-step') }}
            </div>
            <div class="test-results__tape">
              <div class="container h-100">
                <div class="test-results__tape-text">
                  {{ $t('test-results.title-2') }}
                </div>
              </div>
            </div>
            <div class="test-results__date-block">
              <div class="test-results__date">
                <img src="/images/test-date.svg" class="mr-3" width="24" height="24">
                {{ $t('test-results.test-date') }}
                {{ dataEnd.substr(0, 10).replace('-', '.').replace('-', '.') }}
              </div>
            </div>
            <div style="margin-top: 40px" class="test-results__sub-title">{{ $t('test-results.results') }}</div>
            <section class="test-results__section" v-for="(chart, index) in charts" :key="index" :id="'chart-' + index">
              <div class="test-results__sub-title">{{ chart.subject.name }}</div>
              <div v-for="(topic, key) in chart.topics" class="test-results__section-row mb-5">
                <div class="test-results__img">
                  <img height="400px" :src="topic.topic.image">
                </div>
                <div class="test-results__card">
                  <div class="test-results__card-title--recomend">
                    {{topic.topic.name}}
                  </div>
                  <div class="recomendation-wh">
                    <div class="test-results__card-title--recomend-min">
                      {{ topic.level.name }}
                    </div>

                    <div>{{ topic.recommendation }}</div>
                  </div>

                  <div v-if="topic.result_recommendation"  class='recomendation-bg'>
                    <div class="test-results__card-title--recomend-min">{{ $t('profile.children.recommendation') }}</div>
                    {{ topic.result_recommendation }}
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="recommendation-title">{{ $t('profile.children.recommendation') }}</div>
              <div class="recomendation-wrapper">
                <div>
                  {{second_step}}
                </div>
              </div>
            </section>
        </div>
          </div>
      </vue-html2pdf>



      <ul class="sponsor-list mt-5">
        <li><a href="https://fnn.kz" target="_blank" class="sponsor-fond-link"><i
            v-bind:class="{'sponsor-fond': this.$i18n.locale === 'ru', 'sponsor-fond-kz': this.$i18n.locale === 'kz'}"></i></a>
        </li>
        <li><a href="#" target="_blank" class=" sponsor-fond-circle-logo">
          <img src="/images/EA_logo_final.svg" width="46" alt=""
               class="img-fluid ">
        </a></li>
        <li class="sponsor-fond-circle-logo"><img src="/images/s-3.png" width="46" alt="" class="img-fluid"></li>
      </ul>
      <div class="test-results__btns mt-5">
        <div class="test-results__download-block">
          <button @click="generateReport()" class="test-results__download-btn">
            <img src="/images/download-icon.svg" width="16" height="16" class="mr-2">
            {{ $t('test-results.download-file') }}
          </button>
          <div class="test-results__download-title">
            {{ $t('test-results.download-file-name') + ' ' + fio +'.pdf' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueApexCharts,
    VueHtml2pdf
  },
  data() {
    return {
      fio:'',
      subjectRecommendation:'',
      special_quiz_available: false,
      user: '',
      id: undefined,
      second_step:'',
      results: {},
      passedDate: new Date(),
      charts: {},
      chartSeries: [],
      chartOptions: {},
      subjects: [],
      topics: [],
      radialSeries: [],
      radialOptions: {},
      timeTotal: '',
      dataEnd: ''
    }
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    dataPointSelectionHandler(event, chartContext, config) {
      if (event.target.classList[1] != null && event.target.classList[1] != 'apexcharts-align-center') {
        let selectedBlock = event.target.classList[1].slice(-1);
        let allTopics = document.querySelectorAll(`#chart-${chartContext.w.config.chart.id} .topic-block`);
        let selectedTopic = document.querySelector(`#chart-${chartContext.w.config.chart.id} #topic-${selectedBlock}`);
        allTopics.forEach((topic, key) => {
          if (topic != null) {
            topic.parentElement.classList.add('d-none');
          }
        });
        if (selectedTopic != null) {
          selectedTopic.parentElement.classList.remove('d-none');
        }
      }
    },
    formatDate(date, format) {
      let month = date.getMonth() + 1;
      const map = {
        mm: (month < 10) ? '0' + month : month,
        dd: date.getDate(),
        yy: date.getFullYear(),
        yyyy: date.getFullYear()
      }
      return format.replace(/mm|dd|yy|yyy/gi, matched => map[matched])
    },
    getResult: async function () {
      this.id = this.$route.query.id
        this.$http.get(window.API_ROOT + '/api/chart/second-quiz/' + this.id)
            .then((res) => {
              this.special_quiz_available=res.body.special_quiz_available
              this.dataEnd = res.body.datetime.end;
              this.timeTotal=res.body.duration;

              var hours = Math.floor(this.timeTotal / 60 / 60);
              var minutes = Math.floor(this.timeTotal / 60) - (hours * 60);
              var seconds = this.timeTotal % 60;

              this.timeTotal=hours + ':' + minutes + ':' + seconds;
              this.charts = res.body.list;
              this.second_step=res.body.final_text
              this.fio=res.body.fullname

            })

    }
  },
  mounted() {
    this.getResult()
  }
}
</script>
<style>
.recomendation-wrapper {
  margin-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%), 0px 4px 16px rgb(0 0 0 / 8%);
  border-radius: 15px;
  padding: 30px 27px;
  font-family: "Roboto", sans-serif;
}

.recommendation-title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.apexcharts-legend {
  padding-top: 21px;
  margin-top: 1rem !important;
  inset: auto 0px -2px !important;
  position: absolute;
  max-height: 166.667px;
}

.sponsor-list .sponsor-fond-link .sponsor-fond {
  width: 120px;
  height: 87px;
  background-size: contain;
  display: block;
  background-repeat: no-repeat;
}

.sponsor-fond-circle-logo img {
  width: 110px;
  height: 110px;
}

.sponsor-fond-elumiti img {
  width: 60px;
}

.sponsor-list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 0;
}

.sponsor-list li {
  margin-right: 35px;
}

.nav-scroller {
  display: none !important;
}

.test-results__title {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #343F68;
  padding-top: 60px;
  padding-bottom: 40px;
}

.test-results__tape {
  width: calc(100% - 50px);
  height: 65px;
  border-right: 33px solid transparent;
  border-top: 33px solid #1B83B1;
  border-left: 33px solid #1B83B1;
  border-bottom: 33px solid #1B83B1;
  position: absolute;
  left: 0;
}

.test-results__tape-text {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  padding-left: 24px;
}

.test-results__date-block {
  margin-top: 113px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.test-results__date {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 14px 16px;
  align-items: center;
  font-size: 20px;
  line-height: 23px;
  color: #303030;
}

.test-results__date-block .test-results__date:first-child {
  margin-right: 20px;
}

.test-results__sub-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  padding-bottom: 40px;
}

.test-results__section {
  margin-top: 80px;
}

.test-results__section-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.test-results__card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 30px 27px;
  width: 553px;
  height: fit-content;
  font-family: "Roboto", sans-serif;
  margin-left: 20px;
}

.test-results__card-title {
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  color: #1B83B1;
  text-align: center;
  padding-bottom: 16px;
}

.test-results__card-title--yellow {
  color: #F3A93C;
}

.test-results__card-title--recomend {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #1B83B1;
}

.recomendation-bg {

  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: #e0f4fd;
}

.recomendation-wh {
  padding: 5px 10px 10px 30px;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: white;
}

.test-results__card-title--recomend-min {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #1B83B1;
}

.test-results__card-sub-title {
  color: #F3A93C;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 28px;
  text-align: center;
}

.test-results__card-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
}

.test-results__btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 84px;
  align-items: center;
}

.test-results__other-btn,
.test-results__consult-btn {
  border: none;
  border-radius: 5px;
  padding: 10px 24px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  height: fit-content;
  outline: none;
}

.test-results__other-btn {
  background: #1B83B1;
}

.test-results__consult-btn {
  background: #18A0FB;
  margin-left: 24px;
  margin-right: 24px;
}

.test-results__download-block {
  display: flex;
  flex-direction: row;
  padding: 4px;
  border-radius: 4px;
  border: 1px dashed #1B83B1;
  align-items: center;
}

.test-results__download-btn {
  color: #0070D2;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  padding: 5px 23px;
  outline: none;
}

.test-results__download-title {
  color: #737373;
  font-size: 13px;
  line-height: 20px;
  margin-left: 22px;
  margin-right: 12px;
}

.test-results__img {
  width: 425px;
  display: flex;
  justify-content: center;
}

.apexcharts-radialbar .apexcharts-datalabels-group {
  display: none;
}

@media screen and (max-width: 1200px) {
  .test-results__card {
    width: 453px;
  }
}

@media screen and (max-width: 991px) {
  .test-results__tape-text {
    padding-left: 0;
  }

  .test-results__section-row {
    flex-direction: column;
  }

  .test-results__card {
    margin-left: 0;
    margin-top: 24px;
  }

  .test-results__date-block {
    flex-direction: column;
    align-items: center;
  }

  .test-results__date-block .test-results__date:first-child {
    margin-right: 0;
    margin-bottom: 12px;
    width: fit-content;
  }

  .test-results__btns {
    flex-direction: column;
    align-items: center;
  }

  .test-results__consult-btn {
    margin: 20px 0;
  }

  .test-results__card {
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .test-results__tape-text {
    font-size: 16px;
  }

  .test-results__img {
    width: 100%;
  }

  .test-results__img-recomend {
    width: 312px;
    height: 217px;
  }

  .test-results__img-result {
    width: 312px;
    height: 312px;
  }
}
@media screen and (max-width: 459px) {
.sponsor-list{
  padding-left: 0!important;
  flex-direction: column;
  align-items: none;

}
  .sponsor-list li {
    margin: 1rem auto 0;
  }
  .test-results__btns {

    padding-bottom: 160px;

  }
}

</style>
